import React from 'react';
import { Twitter, Facebook, Linkedin } from "react-feather";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import "./share-bar.component.scss";

const ShareBarComponent = ({ socialConfig }: { socialConfig: any }) => (


  <div className="share-bar-container">
    <div className="share-bar-icons-container ">
      <b>
        <span>COMPARTIR EN:</span>
      </b>
      <br />
      <ul className="share-bar-icons">
        <li>
          <FacebookShareButton
            url={socialConfig.config.url}
            className="share-button"
            quote={`${socialConfig.config.title} - ${socialConfig.config.description}`}
            hashtag="#Quepler"
          >
            <Facebook className="share-button-icon" />
            <span className="share-button-name">Facebook</span>
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton
            url={socialConfig.config.url}
            className="share-button"
            title={socialConfig.config.title}
            // via={socialConfig.twitterHandle.split('@').join('')}
          >
            <Twitter className="share-button-icon" />
            <span className="share-button-name">Twitter</span>
          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton
            url={socialConfig.config.url}
            className="share-button"
            title={socialConfig.config.title}
          >
            <Linkedin className="share-button-icon" />
            <span className="share-button-name">Linkedin</span>
          </LinkedinShareButton>
        </li>
        <li>

          <WhatsappShareButton
            url={socialConfig.config.url}
            className="share-button"
            title={socialConfig.config.title}
          >
            <div className="share-button-icon" style={{ margin: '0' }}>
              <svg style={{ marginRight: '5px', marginBottom: '-4px' }} xmlns="http://www.w3.org/2000/svg"
                width="26" height="24" viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 11.5a8.37 8.37 0 0 1-.9 3.8 8.49 8.49 0 0 1-7.6 4.7 8.37 8.37 0 0 1-3.8-.9L3 21l1.9-5.7a8.37 8.37 0 0 1-.9-3.8 8.49 8.49 0 0 1 4.7-7.6 8.37 8.37 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8Z" />
                <path d="M9.49 10a7.58 7.58 0 0 0 .72 1.42A8 8 0 0 0 14 14.5M9.49 10a7.47 7.47 0 0 1-.4-1.4.51.51 0 0 1 .52-.6h0a.54.54 0 0 1 .51.37l.38 1.13ZM14 14.5a7.8 7.8 0 0 0 1.43.41.51.51 0 0 0 .6-.52h0a.54.54 0 0 0-.37-.51l-1.16-.38Z" />
              </svg>
              <span className="share-button-name">WhatsApp</span>
            </div>
          </WhatsappShareButton>
        </li>
      </ul>
    </div>
  </div>
);

export default ShareBarComponent;

