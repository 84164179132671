import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ShareBarComponent from '../components/share-bar/share-bar.component';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { getNumbersDate, getSpanishDate } from '../functions/date.function';
import { BlogLayout } from '../layouts/blog/blog.layout';
import { SeoComponent } from '../components/seo/seo.component';

export const Head = ({ data }: { data: any }) => {

  const { siteMetadata } = data.site;
  const { title, author, date, description, thumbnail } = data.mdx.frontmatter;

  const blogMetaData = {
    title: "Blog - Quepler Technology | " + title,
    siteUrl: siteMetadata.siteUrl,
    url: siteMetadata.siteUrl + "/blog/" + data.mdx.slug.substring(0, data.mdx.slug.length - 1),
    author: author,
    lang: siteMetadata.lang,
    charSet: siteMetadata.charSet,
    image: thumbnail.childImageSharp.gatsbyImageData.images.fallback.src,
    description: "Blog - Quepler Technology | " + description,
    twitterUsername: siteMetadata.twitterUsername,
    menuLinks: siteMetadata.menuLinks
  }

  return (
    <>
     <meta name="revised" content={"Quepler Technology, " + getNumbersDate(date)} />
     <meta name="resource-type" content="Blog"></meta>
     <SeoComponent {...blogMetaData}/>
     </>
 );
} 

const BlogPostTemplate = ({ data }: { data: any }) => {

  const { siteMetadata } = data.site;
  const { title, author, date, layout, description, thumbnail } = data.mdx.frontmatter;
  const { body } = data.mdx;

  return (
    <>
      <BlogLayout
        siteMetaData={siteMetadata}
        path="blog"
      >
        <div className="blog-template">
          <div className="blog-container">

            <label className="blog-date">
              <time>{getSpanishDate(date)}</time><b>{" "}|{" "}</b>{author}
            </label>

            <h1 className="blog-title">
              {title}
            </h1>

            <ShareBarComponent
              socialConfig={{
                twitterHandle: "quepler.com",
                config: {
                  url: `${siteMetadata.url}/${layout}/${data.mdx.slug}`,
                  title: title,
                  description: description
                },
              }}
            />

            <GatsbyImage className="blog-thumbnail" image={thumbnail.childImageSharp.gatsbyImageData} alt={""} />

            <div className="blog-description">
              <h3 className='blog-description-title'>
                Resúmen:
              </h3>
              <br />
              <p className='blog-description-content'>
                {description}
              </p>
            </div>

            <div className="line"></div>

            <div className="blog-content">
              <div className="blog-content-mdx-container">
                <div className="blog-content-mdx">
                  <MDXRenderer localImages={thumbnail.childImageSharp.gatsbyImageData} >{body}</MDXRenderer>
                </div>
              </div>
            </div>
            <div className="line"></div>
          </div>
        </div>
      </BlogLayout>
    </>
  )
}

export default BlogPostTemplate;

export const getPostQuery = graphql`
  # $slug --> comes from the gatsby-node file (context)
  query getPost($id: String!) {
    mdx(id: {eq: $id}) {
      slug
      frontmatter {
        date
        author
        title
        layout
        description
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
    site {
      siteMetadata {
        author
        description
        image
        menuLinks {
          link
          name
        }
        siteUrl
        title
        url
        lang
        charSet
      }
    }
  }
`